/** @jsx jsx */
import { useEffect } from 'react';
import { Router } from '@reach/router';
import Layout from '../layouts/Layout';
import { CustomerRoute } from '../components/account/CustomerRoute';
import { Profile } from '../components/account/Profile';
import { Default } from '../components/account/Default';
import { Column, Container, Row, Section } from '@nimles/react-web-components';
import { QuoteRequest } from '../components/account/quotes/QuoteRequest';
import {
  loadOrders,
  loadPublicCategories,
  loadPublicOrganization,
  loadPublicProductGroups,
  loadPublicProductProperties,
  loadQuoteRequests,
  loadQuotes,
  signOutUser,
} from '@nimles/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { jsx } from '@emotion/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Navbar, NavbarButton, NavbarLink } from '../components/Navbar';
import { QuoteRequests } from '../components/account/quotes/QuoteRequests';
import { OrganizationModel, QuoteModel } from '@nimles/models';
import { StaticQuery, graphql } from 'gatsby';

const AccountPage = ({ location, site }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );
  const publicOrganizations = useSelector<RootState, OrganizationModel[]>(
    ({ publicOrganizations }) => publicOrganizations.values
  );

  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );

  useEffect(() => {
    dispatch(loadPublicCategories());
    dispatch(loadOrders());
    dispatch(loadQuotes());
    dispatch(loadQuoteRequests());
    dispatch(loadPublicProductGroups());
    dispatch(loadPublicProductProperties());
  }, [accessToken]);

  useEffect(() => {
    quotes
      .map(({ head }) => head.seller.organizationId)
      .filter(
        (organizationId) =>
          !publicOrganizations.some(({ id }) => organizationId === id)
      )
      .forEach((organizationId) =>
        dispatch(loadPublicOrganization(organizationId))
      );
  }, [quotes]);

  const handleSignOut = () => {
    dispatch(signOutUser());
  };

  const { pathname } = location;

  return (
    <Layout
      header
      footer
      headerAlwaysOpen
      location={location}
      siteMetadata={site.siteMetadata}
    >
      <Section>
        <Navbar>
          <Container>
            <Row wrap="wrap">
              <Column>
                <NavbarLink
                  to="/account/profile"
                  isActive={pathname.indexOf('/account/profile') === 0}
                  activeClassName="active"
                >
                  {t('title.myProfile')}
                </NavbarLink>
              </Column>
              <Column>
                <NavbarLink
                  to="/account/quoterequests"
                  isActive={pathname.indexOf('/account/quoterequests') === 0}
                  activeClassName="active"
                >
                  {t('title.myQuoteRequests')}
                </NavbarLink>
              </Column>
              <Column flex></Column>
              <Column>
                <NavbarButton onClick={handleSignOut}>
                  {t('action.signOut')}
                </NavbarButton>
              </Column>
            </Row>
          </Container>
        </Navbar>
        <Container>
          <Router basepath="/account">
            <CustomerRoute path="/profile" component={Profile} />
            <CustomerRoute path="/quoterequests" component={QuoteRequests} />
            <CustomerRoute
              path="/quoterequests/:quoteRequestId"
              component={QuoteRequest}
            />
            <Default path="/" />
          </Router>
        </Container>
      </Section>
    </Layout>
  );
};

export const Account = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            tenantId
            googleApiKey
          }
        }
      }
    `}
    render={(props) => <AccountPage {...props} location={location} />}
  />
);

export default Account;
