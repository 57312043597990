import React, { useEffect, useMemo } from 'react';
import { QuoteModel, QuoteRequestModel } from '@nimles/models/lib/e-commerce';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Column,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { Link } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import {
  CategoryModel,
  OrganizationModel,
} from '@nimles/models/lib/organization-management';
import { ProductGroupModel } from '@nimles/models/lib/pim';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { loadPublicOrganization } from '@nimles/react-redux';

export const QuoteRequests = () => {
  const { t } = useTranslation();
  const quoteRequests = useSelector<RootState, QuoteRequestModel[]>(
    ({ quoteRequests }) => quoteRequests.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const publicOrganizations = useSelector<RootState, OrganizationModel[]>(
    ({ publicOrganizations }) => publicOrganizations.values
  );

  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );

  return (
    <Row>
      <Column xs={100}>
        <h2>{t('title.myQuoteRequests')}</h2>
        {quoteRequests
          .sort(({ lastModified: a }, { lastModified: b }) =>
            moment(a).isBefore(moment(b)) ? 1 : -1
          )
          .map(
            ({
              id,
              head: { quoteRequestNumber, quoteRequestDate, categoryId },
              lines,
              lastModified,
            }) => {
              const groups = lines.map(
                ({ id, categoryId, productGroupId }) =>
                  `${
                    publicCategories.find(({ id }) => id === categoryId)
                      ?.name ?? ''
                  } ${
                    publicProductGroups.find(({ id }) => id === productGroupId)
                      ?.name ?? ''
                  }`
              );

              const category = publicCategories.find(
                ({ id }) => id === categoryId
              );

              const connectedQuotes = quotes.filter(
                ({ head }) => head.quoteRequestId === id
              );

              return (
                <Link to={`/account/quoterequests/${id}`}>
                  <Card>
                    <CardHeader>
                      <CardTitle>{category?.name}</CardTitle>
                      <CardSubtitle>
                        {moment(lastModified).format('MMMM D')}
                      </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                      {t('title.quotes')}
                      <List>
                        {connectedQuotes.map(
                          ({
                            id,
                            head: {
                              totalNetPrice,
                              seller: { organizationId },
                            },
                            lines,
                          }) => {
                            const organization = publicOrganizations.find(
                              ({ id }) => organizationId === id
                            );
                            const organizationName =
                              organization?.name ?? organizationId;

                            const address = organization?.address;
                            return (
                              <ListItem key={id}>
                                <Row>
                                  <Column flex>{organizationName}</Column>
                                  <Column>{totalNetPrice} €</Column>
                                </Row>
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    </CardBody>
                  </Card>
                </Link>
              );
            }
          )}
      </Column>
    </Row>
  );
};
