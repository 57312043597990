import React from 'react';
import {
  loadFiles,
  loadPublicFile,
  loadPublicOrganization,
  loadQuoteRequest,
} from '@nimles/react-redux';
import {
  QuoteRequestModel,
  ProductGroupModel,
  ProductPropertyModel,
  CategoryModel,
  FileModel,
  QuoteModel,
  OrganizationModel,
} from '@nimles/models';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Column,
  Image,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import moment from 'moment';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Property, PropertyTitle, PropertyValue } from '../../Property';

export const QuoteRequest = ({ quoteRequestId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quoteRequests = useSelector<RootState, QuoteRequestModel[]>(
    ({ quoteRequests }) => quoteRequests.values
  );
  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicOrganizations = useSelector<RootState, OrganizationModel[]>(
    ({ publicOrganizations }) => publicOrganizations.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const files = useSelector<RootState, FileModel[]>(
    ({ files }) => files.values
  );
  const publicFiles = useSelector<RootState, FileModel[]>(
    ({ publicFiles }) => publicFiles.values
  );
  const publicProductProperties = useSelector<
    RootState,
    ProductPropertyModel[]
  >(({ publicProductProperties }) => publicProductProperties.values);
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  useEffect(() => {
    dispatch(loadFiles());
  }, [accessToken]);

  useEffect(() => {
    if (quoteRequestId) {
      dispatch(loadQuoteRequest(quoteRequestId));
    }
  }, [accessToken, quoteRequestId]);

  const connectedQuotes = useMemo(
    () => quotes.filter(({ head }) => head.quoteRequestId === quoteRequestId),
    [quoteRequestId, quotes]
  );

  useEffect(() => {
    connectedQuotes.forEach(({ lines }) => {
      lines.forEach((line) => {
        if (!line.fileIds) {
          return;
        }
        line.fileIds
          .filter((fileId) => !publicFiles.some(({ id }) => id === fileId))
          .forEach((fileId) => dispatch(loadPublicFile(fileId)));
      });
    });
  }, [connectedQuotes]);

  const quoteRequest = useMemo(
    () => quoteRequests.find(({ id }) => id === quoteRequestId),
    [quoteRequests, quoteRequestId]
  );

  if (!quoteRequest) {
    return null;
  }

  const {
    head: { quoteRequestNumber, quoteRequestDate, categoryId },
    lines,
  } = quoteRequest;

  const category = publicCategories.find(({ id }) => id === categoryId);

  return (
    <Row wrap="wrap">
      <Column xs={100} xxl={60}>
        <h2>{t('title.quoteRequest')}</h2>
        <Card>
          <CardHeader>
            <CardTitle>{category?.name}</CardTitle>
            <CardSubtitle>
              {moment(quoteRequestDate).format('MMMM D')}
            </CardSubtitle>
            <List>
              <ListItem>Number: {quoteRequestNumber}</ListItem>
              <ListItem></ListItem>
            </List>
          </CardHeader>
          <CardBody>
            {lines.map(
              ({
                id,
                categoryId,
                productGroupId,
                productProperties,
                description,
                fileIds,
              }) => {
                const category = publicCategories.find(
                  ({ id }) => id === categoryId
                );
                const productGroup = publicProductGroups.find(
                  ({ id }) => id === productGroupId
                );

                return (
                  <div key={id}>
                    <h4>{productGroup?.name}</h4>
                    {productProperties.map(({ productPropertyId, values }) => {
                      const productProperty = publicProductProperties.find(
                        ({ id }) => id === productPropertyId
                      );

                      if (!productProperty) {
                        return (
                          <div key={productPropertyId}>{productPropertyId}</div>
                        );
                      }

                      const {
                        id,
                        name,
                        title,
                        propertyType,
                        options,
                      } = productProperty;

                      return (
                        <Property key={id}>
                          <PropertyTitle>{title}</PropertyTitle>
                          {values?.map((value, index) => (
                            <PropertyValue key={index}>
                              {propertyType.indexOf('Option') !== -1
                                ? options.find(
                                    ({ id }) => value === id.replace(/-/g, '')
                                  )?.name ?? value
                                : value}
                            </PropertyValue>
                          ))}
                        </Property>
                      );
                    })}
                    <PropertyTitle>Description</PropertyTitle>
                    <PropertyValue
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <PropertyTitle>Files</PropertyTitle>
                    {fileIds
                      ?.map(
                        (fileId) =>
                          files.find(({ id }) => id === fileId) ?? {
                            id: fileId,
                          }
                      )
                      .map(({ id, name, mimeType, uri }) => (
                        <ListItem key={id}>
                          <Row wrap="wrap" align="center">
                            <Column xs={100} sm={20} align="center">
                              {!mimeType ? (
                                id
                              ) : mimeType.toLowerCase()?.indexOf('image') ===
                                0 ? (
                                <Image src={uri} alt="" />
                              ) : (
                                mimeType
                                  ?.split('/')
                                  .map((s, index) => <div key={index}>{s}</div>)
                              )}
                            </Column>
                            <Column flex>
                              <div>{name}</div>
                            </Column>
                          </Row>
                        </ListItem>
                      ))}
                  </div>
                );
              }
            )}
          </CardBody>
        </Card>
      </Column>
      <Column xs={100} xxl={40}>
        <h2>{t('title.quotes')}</h2>
        {connectedQuotes.map(
          ({
            head: {
              totalNetPrice,
              seller: { organizationId },
            },
            lines,
          }) => {
            const organization = publicOrganizations.find(
              ({ id }) => organizationId === id
            );
            const organizationName = organization?.name ?? organizationId;

            const address = organization?.address;
            return (
              <Card>
                <CardHeader>
                  <CardTitle>{organizationName}</CardTitle>
                  <CardSubtitle>
                    {address
                      ? `${address?.street}, ${address.postalCode} ${address.city}`
                      : null}
                  </CardSubtitle>
                  <div>{totalNetPrice}</div>
                </CardHeader>
                <CardBody>
                  {lines.map(
                    ({
                      id,
                      categoryId,
                      productGroupId,
                      productProperties,
                      description,
                      fileIds,
                    }) => {
                      const category = publicCategories.find(
                        ({ id }) => id === categoryId
                      );
                      const productGroup = publicProductGroups.find(
                        ({ id }) => id === productGroupId
                      );

                      return (
                        <div key={id}>
                          <h4>{productGroup?.name}</h4>
                          {productProperties?.map(
                            ({ productPropertyId, values }) => {
                              const productProperty = publicProductProperties.find(
                                ({ id }) => id === productPropertyId
                              );

                              if (!productProperty) {
                                return (
                                  <div key={productPropertyId}>
                                    {productPropertyId}
                                  </div>
                                );
                              }

                              const {
                                id,
                                name,
                                title,
                                propertyType,
                                options,
                              } = productProperty;

                              return (
                                <Property key={id}>
                                  <PropertyTitle>{title}</PropertyTitle>
                                  {values?.map((value, index) => (
                                    <PropertyValue key={index}>
                                      {propertyType.indexOf('Option') !== -1
                                        ? options.find(
                                            ({ id }) =>
                                              value === id.replace(/-/g, '')
                                          )?.name ?? value
                                        : value}
                                    </PropertyValue>
                                  ))}
                                </Property>
                              );
                            }
                          )}
                          <PropertyTitle>Description</PropertyTitle>
                          <PropertyValue
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                          <PropertyTitle>Files</PropertyTitle>
                          {fileIds
                            ?.map(
                              (fileId) =>
                                publicFiles.find(({ id }) => id === fileId) ?? {
                                  id: fileId,
                                }
                            )
                            .map(({ id, name, mimeType, uri }) => (
                              <ListItem key={id}>
                                <Row wrap="wrap" align="center">
                                  <Column xs={100} sm={20} align="center">
                                    {!mimeType ? (
                                      id
                                    ) : mimeType
                                        .toLowerCase()
                                        ?.indexOf('image') === 0 ? (
                                      <Image src={uri} alt="" />
                                    ) : (
                                      mimeType
                                        ?.split('/')
                                        .map((s, index) => (
                                          <div key={index}>{s}</div>
                                        ))
                                    )}
                                  </Column>
                                  <Column flex>
                                    <div>{name}</div>
                                  </Column>
                                </Row>
                              </ListItem>
                            ))}
                        </div>
                      );
                    }
                  )}
                </CardBody>
              </Card>
            );
          }
        )}
      </Column>
    </Row>
  );
};
